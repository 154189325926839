import Layout from '@/layouts';
import { NextPage } from 'next';

import styled, { DefaultTheme } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  .title {
    font-weight: 500;
    font-size: 56px;
    line-height: 66px;
    align-items: center;
    align-self: center;
    letter-spacing: -0.02em;
    color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.white};
  }
`;

const Error404Page: NextPage<{
  errorMesage?: string;
}> = (props) => {
  return (
    <Layout>
      <Container>
        <p className="title">
          {props.errorMesage ? props.errorMesage : 'Page not found'}
        </p>
      </Container>
    </Layout>
  );
};

export default Error404Page;
